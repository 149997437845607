import Image from 'next/image';
import Skeleton from 'react-loading-skeleton';
import clsx from 'clsx';
import { useState } from 'react';
import { createCloudimgLoader } from '@quno/patient-journey/src/utils/cloudimg';
import { useTranslation } from '@quno/patient-journey/src/hooks/useTranslation';
import { ClockIcon } from '../icons/Clock';
import styles from './InformationSection.module.scss';
import { InformationIcon } from './InformationIcon';
import type { BookingAvalabilityOptionsSuccess } from '@quno/patient-journey/types/BookingAvalabilityOptions';

type InformationSectionProps = {
  information?: BookingAvalabilityOptionsSuccess['information'];
  loading?: boolean;
  onSelectedDoctor?: (doctorSub: string | undefined) => void;
};

export const InformationSection = ({
  information,
  loading,
  onSelectedDoctor,
}: InformationSectionProps): JSX.Element => {
  const { t } = useTranslation(true);
  const [selectedDoctor, setSelectedDoctor] = useState<string | undefined>();
  const { durationMinutes, clinicAddress, clinicName, meetingType, doctors } =
    information || {};

  const handleSelectDoctor = (selectedDoctorSub: string | undefined): void => {
    setSelectedDoctor?.(selectedDoctorSub);
    onSelectedDoctor?.(selectedDoctorSub);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.information}>
        <div className={styles.duration}>
          <div className={styles.item}>
            {loading ? (
              <Skeleton />
            ) : (
              <>
                <InformationIcon meetingType={meetingType!} />{' '}
                {t(`calendar-booking.type.${meetingType}`)}
              </>
            )}
          </div>
          {information?.dayPrecision === 'time' && (
            <>
              <div className={styles.separator} />
              <div className={styles.item}>
                {loading ? (
                  <Skeleton />
                ) : (
                  <>
                    <ClockIcon />
                    {`${durationMinutes} ${t('calendar-booking.minutes')}`}
                  </>
                )}
              </div>
            </>
          )}
        </div>

        <div className={styles.clinic}>
          <p>
            <b className={styles.name}>{loading ? <Skeleton /> : clinicName}</b>
          </p>
          <p className={styles.address}>
            {loading ? <Skeleton /> : clinicAddress}
          </p>
        </div>
      </div>
      <div className={styles.doctors}>
        {loading ? (
          <div className={styles.skeletonContainer}>
            <div className={styles.skeletonWrapper}>
              <Skeleton className={styles.photo} />
              <div className={styles.info}>
                <Skeleton className={styles.nameSkeleton} />
              </div>
            </div>

            <div className={styles.skeletonWrapper}>
              <Skeleton className={styles.photo} />
              <div className={styles.info}>
                <Skeleton className={styles.nameSkeleton} />
                <Skeleton className={styles.titleSkeleton} />
              </div>
            </div>
          </div>
        ) : (
          doctors?.map(({ name, picture, title, sub, postTitle }, i) => {
            const titles = [title?.trim(), postTitle?.trim()]
              .filter(Boolean)
              .join(', ');

            return (
              <div
                key={i}
                className={clsx(
                  styles.doctor,
                  (sub === selectedDoctor ||
                    information?.doctors?.length === 1) &&
                    styles.selected,
                )}
                onClick={() => handleSelectDoctor(sub)}
              >
                <div>
                  <Image
                    loader={createCloudimgLoader()}
                    src={picture || '/v2/images/doctor-placeholder.jpg'}
                    alt={`${name} avatar`}
                    width={108}
                    height={108}
                  />
                </div>
                <div className={styles.info}>
                  <p className={styles.name}>{name}</p>
                  <p className={styles.title}>{titles}</p>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
