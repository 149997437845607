import Skeleton from 'react-loading-skeleton';
import styles from './LoadingCalendar.module.scss';

export const LoadingCalendar = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.calendar}>
      <h2>
        <Skeleton />
      </h2>

      <Skeleton height={313} />
    </div>

    <div className={styles.slot}>
      <h2>
        <Skeleton />
      </h2>

      <h2>
        <Skeleton count={3} />
      </h2>
    </div>
  </div>
);
