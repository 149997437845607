export const PhoneIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M5.33084 1.13042C5.10527 0.585522 4.51057 0.295496 3.94223 0.450763L1.36422 1.15386C0.854477 1.29448 0.5 1.75735 0.5 2.28467C0.5 9.53239 6.37669 15.4091 13.6244 15.4091C14.1517 15.4091 14.6146 15.0546 14.7552 14.5449L15.4583 11.9669C15.6136 11.3985 15.3236 10.8038 14.7787 10.5782L11.9663 9.40642C11.4888 9.20721 10.9351 9.3449 10.6099 9.74625L9.42636 11.1905C7.36396 10.215 5.69411 8.54513 4.71856 6.48272L6.16284 5.30211C6.56419 4.974 6.70188 4.42324 6.50267 3.94573L5.33084 1.13335V1.13042Z"
      fill="currentColor"
    />
  </svg>
);
