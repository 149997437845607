import styles from './CalendarErrorMessage.module.scss';

type CalendarErrorMessageProps = {
  title: string;
  description: string;
};

export const CalendarErrorMessage = ({
  title,
  description,
}: CalendarErrorMessageProps): JSX.Element => {
  return (
    <div className={styles.container}>
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  );
};
