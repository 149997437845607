import type { BookingAvalabilityOptions } from '@quno/patient-journey/types/BookingAvalabilityOptions';

export const fetchBookingAvailability = async (
  eventSetupId?: string,
  junoId?: string,
  inviteId?: string,
  contentfulPackageId?: string,
): Promise<BookingAvalabilityOptions> => {
  const url = new URL(window.location.origin);

  url.pathname = '/api/calendar/booking-availability';

  if (eventSetupId) {
    url.searchParams.append('eventSetupId', eventSetupId);
  }

  if (junoId) {
    url.searchParams.append('junoId', junoId);
  }

  if (inviteId) {
    url.searchParams.append('inviteId', inviteId);
  }

  if (contentfulPackageId) {
    url.searchParams.append('contentfulPackageId', contentfulPackageId);
  }

  url.searchParams.append('origin', window.location.origin);

  const response = await fetch(url);

  const bookingAvalabilityOptions = await response.json();

  return bookingAvalabilityOptions;
};
