export const LocationIcon = (): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M512 68.191078c-171.531702 0-310.666552 139.133827-310.666552 310.666552 0 232.999658 310.666552 576.951291 310.666552 576.951291s310.666552-343.951633 310.666552-576.951291C822.665529 207.324906 683.531702 68.191078 512 68.191078zM512 489.809605c-61.245899 0-110.951975-49.706075-110.951975-110.951975S450.754101 267.904633 512 267.904633 622.951975 317.611731 622.951975 378.857631 573.245899 489.809605 512 489.809605z"
      fill="currentColor"
    />
  </svg>
);
