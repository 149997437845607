import { PhoneIcon } from '../icons/Phone';
import { WebcallIcon } from '../icons/Webcall';
import { LocationIcon } from '../icons/Location';
import type { BookingAvalabilityOptionsSuccess } from '@quno/patient-journey/types/BookingAvalabilityOptions';

type InformationIconProps = {
  meetingType: BookingAvalabilityOptionsSuccess['information']['meetingType'];
};

export const InformationIcon = ({
  meetingType,
}: InformationIconProps): JSX.Element => {
  if (meetingType === 'phonecall') {
    return <PhoneIcon />;
  }

  if (meetingType === 'webcall') {
    return <WebcallIcon />;
  }

  return <LocationIcon />;
};
