import type { Slot } from 'cronofy-elements';

type CreateAppointmentResponse =
  | {
      inviteId: string;
      junoId: string;
    }
  | {
      error: {
        key: 'unexpected_error';
        reason?: string;
      };
    };

export const createAppointment = async (
  junoId: string,
  slot: Slot,
  eventSetupId?: string,
  inviteId?: string,
  contentfulPackageId?: string,
): Promise<CreateAppointmentResponse> => {
  const body = {
    eventSetupId,
    inviteId,
    junoId,
    slot,
    contentfulPackageId,
  };

  const response = await fetch('/api/calendar/create-appointment', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const createAppointmentResponse = await response.json();

  return createAppointmentResponse;
};
