import { Inter } from 'next/font/google';
import clsx from 'clsx';
import {
  backgroundColorClass,
  type BackgroundColor,
} from '@quno/patient-journey/src/theme/backgroundColorClass';
import styles from './Layout.module.scss';

export type LayoutProps = {
  header?: JSX.Element;
  footer?: JSX.Element;
  children: JSX.Element;
  backgroundColor?: BackgroundColor;
};

const inter = Inter({
  variable: '--font-inter',
  subsets: ['latin'],
  style: ['normal'],
  weight: ['400', '700'],
  preload: true,
});

export const Layout = ({
  header,
  footer,
  backgroundColor,
  children,
}: LayoutProps): JSX.Element => {
  return (
    <div
      className={clsx(
        inter.className,
        styles.root,
        styles.minHeight,
        backgroundColor && backgroundColorClass(backgroundColor),
      )}
    >
      {header}
      <div id="overlay">
        <main className={styles.main}>{children}</main>
        {footer}
      </div>
    </div>
  );
};
