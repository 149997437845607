import styles from './theme.module.scss';

export type BackgroundColor =
  | 'default'
  | 'background-light'
  | 'background-dark'
  | 'primary'
  | 'secondary';

export const backgroundColorClass = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  backgroundColor?: (string & {}) | BackgroundColor | null,
): string | undefined => {
  if (backgroundColor === 'background-light') {
    return styles.lightBackground;
  }

  if (backgroundColor === 'background-dark') {
    return styles.darkBackground;
  }

  if (backgroundColor === 'primary') {
    return styles.primary;
  }

  if (backgroundColor === 'secondary') {
    return styles.secondary;
  }
};
