export const ClockIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
  >
    <path
      d="M12.6875 7.90909C12.6875 9.41751 12.0883 10.8641 11.0217 11.9308C9.95506 12.9974 8.50842 13.5966 7 13.5966C5.49158 13.5966 4.04494 12.9974 2.97833 11.9308C1.91172 10.8641 1.3125 9.41751 1.3125 7.90909C1.3125 6.40067 1.91172 4.95403 2.97833 3.88742C4.04494 2.82081 5.49158 2.22159 7 2.22159C8.50842 2.22159 9.95506 2.82081 11.0217 3.88742C12.0883 4.95403 12.6875 6.40067 12.6875 7.90909ZM0 7.90909C0 9.7656 0.737498 11.5461 2.05025 12.8588C3.36301 14.1716 5.14348 14.9091 7 14.9091C8.85652 14.9091 10.637 14.1716 11.9497 12.8588C13.2625 11.5461 14 9.7656 14 7.90909C14 6.05257 13.2625 4.2721 11.9497 2.95934C10.637 1.64659 8.85652 0.909088 7 0.909088C5.14348 0.909088 3.36301 1.64659 2.05025 2.95934C0.737498 4.2721 0 6.05257 0 7.90909ZM6.34375 4.19034V7.90909C6.34375 8.12784 6.45312 8.33292 6.63633 8.45596L9.26133 10.206C9.56211 10.4083 9.96953 10.3263 10.1719 10.0228C10.3742 9.71924 10.2922 9.31456 9.98867 9.11221L7.65625 7.55909V4.19034C7.65625 3.82667 7.36367 3.53409 7 3.53409C6.63633 3.53409 6.34375 3.82667 6.34375 4.19034Z"
      fill="currentColor"
    />
  </svg>
);
